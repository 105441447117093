
























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { Tab } from '@/contracts/Tabs'

export default defineComponent({
  name: 'TabsHead',
  props: {
    list: {
      type: Array as PropType<Tab[]>,
      default: () => [],
    },
    isVertical: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const onClick = (value: string) => {
      if (props.value === value) return
      emit('input', value)
    }

    return {
      onClick,
    }
  },
  mounted() {
    this.$forceUpdate()
  },
})
