



























































































































import { defineComponent } from '@nuxtjs/composition-api'
import { defineProps } from '@/helpers/defineProps'
import { BlockCardImageListProps } from '@/contracts/BlockCardImageList'
import CardImage from '@/components/Cards/CardImage.vue'
import Tabs from '@/components/Tabs/index.vue'
import Link from '@/components/Link.vue'
import BlockButtons from '@/components/BlockButtons.vue'

export default defineComponent({
  name: 'BlockCardImageList',
  components: { BlockButtons, CardImage, Tabs, Link },
  props: defineProps<BlockCardImageListProps>({
    data: {
      default: () => [],
    },
    withTabs: {
      default: true,
    },
  }),
  setup() {},
})
