












import { Component, Prop, mixins } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import { SectionCore } from '@/mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic.vue'
import BlockCardImageList from '@/components/CardLists/BlockCardImageList.vue'

@Component
class SectionCardImage extends mixins(SectionCore) {
  @Prop({ default: () => [] }) readonly cardsData: ReadonlyArray<any> = []
}

export default defineComponent({
  name: 'SectionCardImage',
  components: { SectionBasic, BlockCardImageList },
  props: SectionCardImage.options.props,
  setup() {},
})
